import axios from 'axios';

const api = axios.create({
	headers: {
		'content-type': 'application/json'
	},
	timeout: 60000
});

api.interceptors.request.use(
	config => {
		const auth = JSON.parse(localStorage.getItem('auth'));
		if (auth) {
			config.headers.common['x-auth-token'] = auth.token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	response => {
		if (response.status === 200 || response.status === 201) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	error => {
		console.log(error)
    if (error.response.status === 401) {
      api.defaults.headers.common['x-auth-token'] = '';
      localStorage.removeItem('auth')
      window.location.reload();
    }
		return Promise.reject(error.response);
	}
);

export default api;